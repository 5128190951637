.search-heading {
    margin-bottom           : 20px;
    padding                 : 10px;
}
.container-car{
    background-color        : rgb(248, 248, 248);
    height                  : 62px;
}
.buttoncl{
    margin-Top              : '14px';
    padding-Right           : "10px";
    background-Color        : "rgb(80 109 139)";
    border-Radius           : '4px'; 
    color                   : 'white'
}
.container-cars{
    background-color        : rgb(248, 248, 248);
    height                  : 100px;
    border-radius           : 5px;
}

.container-card{
    width                   : 100%;
    margin-top              : 10px;
    padding-left            : 20px;
    padding-right           : 20px;

}

element.style {
    width                   : 30%;
    float                   : right;
    padding-top             : 7px;
    background-color        : rgb(248, 248, 248);
    margin-right            : 59px;
    border-radius           : 4px;
}
.body{
    background-color        : rgb(240, 238, 238);
    margin-top              : 10px;

}
.cardsss{
    background-color        : rgb(231, 218, 201);
    border                  : 1px solid rgb(122, 122, 122);
    
}
.bttnsss{
    background-color        : rgb(39, 156, 202);
    color                   : white;
    border                  : 1px solid rgb(179, 179, 179);
    height                  : 40px;
    /* width                : 115px; */
    border-radius           : 10px;
   
}

.bttnsss:hover{
    background-color        : rgb(126, 209, 241);
    color                   : rgb(0, 0, 0);
    border                  : 1px solid rgb(10, 10, 10);
    height                  : 40px;
    /* width                   : 115px; */
    border-radius           : 10px;
   
}


.cardbody{
    background-color        : rgb(221, 220, 219);
}


.container-fluid-s{
    background-color        : #3b9bf7;
    /* background-image        : url(../); */
    background-repeat       : no-repeat;
    background-size         : cover;
    height                  : 80vh;
}
 
.row-s{
    background-color        : #3b9bf7;
    height                  : 80px;
    top                     : 0;
    bottom                  : 0;
    justify-content         : center;
    align-items             : center;
    display                 : flex;
    border                  : 5px black;
    border-width            : 0px 2px 5px 0px;
    margin-left             : -30%;
    border-top              : 1px solid;
   
}

.row-c{
    background-color        : rgb(255, 255, 255);
    height                  : 85px;
    top                     : 0;
    bottom                  : 0;
    justify-content         : center;
    align-items             : center;
    display                 : flex;
    border                  : 0.5px  solid rgb(88, 88, 88) ;
    border-width            : 0px 0px 1px 0px;  
   
}

.row-c:hover{
    background-color        : rgb(182, 182, 182);
    height                  : 85px;
    top                     : 0;
    bottom                  : 0;
    justify-content         : center;
    align-items             : center;
    display                 : flex;
    border                  : 0.5px  solid rgb(88, 88, 88) ;
    border-width            : 0px 0px 1px 0px;  
   
}
.row-flex{
    background-color        : rgb(255, 255, 255);
    height                  : 80px;
    align-items             : center;
    display                 : flex;
    border                  : 0.5px  solid rgb(88, 88, 88) ;
    border-width            : 0px 0px 1px 0px;
   
}

h1{
    text-align              : center;
    color                   : white;

}
h2{
    color                   : black;
    text-align              : center;
}

.cardimg0{
    width                   : 72%;
    height                  : 248px; 
    border-Radius           : 2px; 
    display                 : flex;
    border                  : none;
}

.plus{
    height                  : 100%;
    width                   : 20%;
    margin-left             : 0px;
    justify-content         : center;
    align-items             : center;
    text-align              : center;
    display                 : flex;
    
}

.find{
height                      : 100%;
width                       : 100%;
justify-content             : center;
display                     : flex;
align-items                 : center;
}

.imgssss{
    height                  : 100%;
    margin-right            : 10px;
    display                 : flex;
    justify-content         : center;
    align-items             : center;
}

.btns{
    color                   : white;
    border-radius           : 4px;
    background-color        : rgb(80 109 139);
    margin-top              : 15px ;
    border                  :  none;
    

}
.btns:hover{
    color                   : rgb(26, 26, 26);
    border-radius           : 4px;
    background-color        : rgb(109, 156, 206);
    
}

.form-controlsa{
    width                   : 40%; 
    float                   : right;
    padding-Top             : 15px;
    background-Color        : rgb(248, 248, 248); 
    margin-Right            : 65px;
    border-Radius           : 4px;

}

.container-fluid-Vehicles{
    height                  : 100%;
    background-color        :  #599ef1;
    padding-bottom          : 10%;
}


.row-veh{
    display                 : flex;
    height                  : 90%;
    width                   : 90%;
    
}

.imge-top{
    height                  : min-content;
}

.Cardb{
    margin-left             : 8%;
    width                   : 80%;
    border                  : none;
    height                  : 100%;
    background-color        :  rgba(121, 238, 247, 0.767);
}
h1{
    color                   :rgb(0, 0, 0)

}
p{
    color                   : black;
}

.btns1{
    height                  : 40px;
    background-color        : #599ef1;
    color                   : white;
    border                  : 1px solid gray;
    border-radius           : 5px;
    box-shadow              : black 3px 4px 4px 1px;
}

@media screen and (max-width:600px) {

    .row-veh{

        flex-direction      : column;
    }
    
}

.modal-backdrop.show {
    opacity                 : -0.5;
}


.container-impotrant{
    height: 90vh;
    background-color: white;
}

.row-important{
    gap: 1rem;
    display: flex;
    margin-right: 50px;
}

.col-important{
    height: 100px;
    background-color: #f0f0f0;
    border-radius: 20px;
    
}

.imagess{
    border-radius: 0px;
    height: 60px;
    width: 60px;
    border: none;
    margin-left: 20px;    
}

.txts{
    margin-left: 20px;
    font-size: 13px;
    display: flex;
    justify-content: center;
}
