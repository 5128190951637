.container-fluid-chatroom{
    background-color        : #3b9bf7;
    height                  : 100%;
    border-top              : 1px solid;
}
.main{
   margin-right             : 20%;
}
.heading{
    text-align              : center;
}
.hello{
    border-radius           : 30px;
    background-color        :white;
    display                 : flex;
margin-right                : 40px;}
.image{
    height                  : 60px;
    width                   : 60px;
    
}
.heading2{
    margin-top              : -20px;
}

.icnss{
    margin-left             : 0px;
    cursor                  : pointer;
    position                : relative;
}

.counter{
    width   	            : 20px;
    height                  : 20px;
    background-color        : red;
    color                   : white;
    border-radius           : 50%;
    padding                 : 5px;
    font-size               : 12px;
    margin-top              : -35px;
    display                 : flex;
    align-items             : center;
    justify-content         : center;
    position                : absolute;
}