.container-fluid-uploads{
    height                  : 90vh;
    border-top              : 1px solid;
}

.uploads {
    font-size               : 100px;
    color                   : rgb(0, 0, 0);
    display                 : flex;
    justify-content         : center;
    font-family             : Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.row-uploads {
    height                  : 50vh;
    color                   : aqua;
    background-color        : rgb(221, 207, 207);
    margin-top              : 20px;
    margin-right            : 20px;
    margin-left             : 20px;
    border-radius           : 20px;
}


.loading0 {
    width                   : 980px;
    height                  : 80px;
    display                 : flex;
    margin-left             : 50px;
    justify-content         : center;
    align-items             : center;
    border-radius           : 130x;
    text-align              : center;
    background-image        : url('../../images/loader.gif');
    background-size         : contain;
    background-position     : center;
    background-repeat       : no-repeat;

}