.container-fluid-agree{
    background-color        : #3b9bf7;
    height                  : 90vh;

}
.row-agree{
    display                 : flex;
    text-align              : center;
    justify-content         : center;

}
.row1-agree{
    display                 : flex;
    justify-content         : space-evenly;
    align-items             : center;
    margin-top              : 200px;
}

.btnn1{
    width                   : 100px;
    height                  : 50px;
    border                  : none;
    color                   : white;
    background-color        : rgb(72, 147, 245);
    border-radius           : 4px;
    box-shadow              : inset 0 0 0 0 #b0e2f1;
    transition              : ease-out 0.3s;
    font-size               : 1rem;
    outline                 : none;
}

.btnn2{
    
    width                   : 100px;
    height                  : 50px;
    border                  : none;
    color                   : white;
    background-color        : rgb(72, 147, 245);
    border-radius           : 4px;
    box-shadow              : inset 0 0 0 0 #b0e2f1;
    transition              : ease-out 0.3s;
    font-size               : 1rem;
    outline                 : none;
}
.btnn1:hover{
    box-shadow              : inset 100px 0 0 0 #ffffff;
    cursor                  : pointer;
    color                   : #000;
}
.btnn2:hover{
    box-shadow              : inset 100px 0 0 0 #ffffff;
    cursor                  : pointer;
    color                   : #000;
}