.container-fluid-oitem{
    background-color        : #3b9bf7;
    background-image        : url(../login/background.png);
    background-repeat       : no-repeat;
    background-size         : cover;
    height                  : 80vh;
}
 
.container-oitem{
    height              : 100%;
    background-color    : white;
    width               : 100%;
    margin-left         : 0px;
    margin-top          : 8px;
    
}




.row-oitem {
    background-color: #3b9bf7;
    height: 80px;
    top: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    border: 5px black;
    border-width: 0px 2px 5px 0px;
    margin-left: 0%;
    border-top: 1px solid;
}

.row-carss {
    background-color: rgb(255, 255, 255);
    height: 85px;
    top: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    border: 0.5px solid rgb(88, 88, 88);
    border-width: 0px 0px 1px 0px;
}
.col-bak{
    margin-left: -21%;
}