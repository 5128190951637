.container-taxijob{
    background-color: rgb(255, 255, 255);
    height: 100%;
}

.row-taxijob{
    height: 100%;
    width: 90%;
    background-color: #fffdfd;
    border-radius: 10px;
    color: #636364;
    border: 1px solid lightgray;
    margin-top: 10px;

}

.jobheader{
    display: flex;
}

.taxijobimage{
    display: flex;
    justify-content: end;
    justify-items: end;
    width: 80px;
    height: 80px;
    border: none;
}




.btn2job{
    background-color: #03a9f4;
    border: none;
    height: 40px;
    border-radius: 5px;
    color: white;
    font-family: sans-serif;
    font-weight: bold;
}

.btn2job:hover{
    background-color: #1779a7;
    border: none;
    height: 40px;
    border-radius: 5px;
    color: white;
    font-family: sans-serif;
    font-weight: bold;
}

.rowtext1{
    text-align: start;
    margin-left: 15px;
}
.rowtext{
    text-align: start;
    margin-left: 15px;
    font-size: 17px;
    margin-top: 10px;
    font-family:sans-serif;
}

.imgtxi{
    height: 100px;
    width: 100px;
    border: none;
    margin-left: -50px;
    margin-top: -14px;
}

.Tabss{
    background-color: rgb(255, 255, 255);
}
.inputh1{
    background-color: #ffffff;
    height: 50px;
    text-align: center;
    color: #ffcc00;
    margin: 0px;
    width: 85%;
    border-radius: 10px;
}
.bttnss{
    display: flex;
    justify-content: center;
  
}
.bt{
    background-color: #cc0000;
    border: none;
    border-radius: 8px;
    color: white;
    height: 40px;
}

.container-taxi{
    background-color: rgb(255, 255, 255);
    height: 88vh;
    width: 100%;
    
}
.row-taxijobs{
    display: flex;
    justify-content: center;
}
.giver23{
    text-align: end;
}
.whatsapp_float{
    color:rgb(41, 226, 41);
    margin-left: 10px;
    outline: none;
}
.whatsapp_float:hover{
    color:rgb(92, 196, 92);
    margin-left: 10px;
    outline: none;
}

.mail_float{
    color:rgb(211, 68, 68);
    margin-left: 10px;
    outline: none;
}

.closebtns{
    display: flex;
    justify-content: end;
    margin-right: 10px;
    margin-top: 10px;
    color: #bd1e1e;
}

.mail_float:hover{
    color:rgb(218, 28, 28);
    margin-left: 10px;
    outline: none;
}
.phone_float{
    color:rgb(37, 103, 245);
    outline: none;
}
.phone_float:hover{
    color:rgb(9, 83, 243);
    outline: none;

}


.phoneicon{
    display: flex;
    justify-content: end;
    margin-right: 20px;
    
}

.row-taxidetailss{
    width: 92%;
}

.textareas{
    width: 148%;
    height: 100px;
    border: 1px solid rgb(39, 39, 39);
    outline: none;
}
p{
    font-size: 18px;
}

.row-dt{
    display: flex;
}

.cmpnyname{
    width: 80%;
    border: 1px solid rgb(39, 39, 39);
    height: 30px;
}


.inputsss{
    border: 1px solid rgb(39, 39, 39);
    outline: none;

}

h6{
    font-size: 15px;
}

@media screen and (max-width: 580px) {
    .inputsss{
        border: 1px solid rgb(39, 39, 39);
        height: 20px;
        width: 126px;
        font-size: 10px;
    
    }
    h6{
        font-size: 12px;
    }
   
    .imgtxi{
        height: 100px;
        width: 100px;
        border: none;
        margin-left: -50px;
    }
}


@media screen and (max-width: 390px) {
    .inputsss{
        border: 1px solid rgb(39, 39, 39);
        height: 20px;
        width: 126px;
    font-size: 10px;
    
    }
    h6{
        font-size: 10px;
    }
  
    .imgtxi{
        height: 100px;
        width: 100px;
        border: none;
        margin-left: -70px;
    }
    .bttnss{
        display: flex;
        justify-content: left;
    }
}

.row-taxidetailss {
    overflow-x: auto;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th,
  td {
    text-align: left;
    padding: 8px 5px;
  }
  tr:nth-child(even) {
    background-color: #e2e2e2;
  }
  tr:nth-child(odd) {
    background-color: #cacaca;
  }

  @media screen and (max-width: 750px) {

    .bt{
        background-color: #cc0000;
        border: none;
        border-radius: 8px;
        color: white;
        height: 40px;
        font-size: 11px;
    }
  }

  .requestbtn{
    background-color: rgb(9 161 9);
    height: 35px;
    font-size: 15px;
    width: 100px;
    border: none;
    border-radius: 5px;
    color: white;
  }