.container-fluid-navbar{
  height: 10vh;
  width: 100%;
  background-color: #3b9bf7;
}

.sidebar {
  margin: 0;
  padding: 0;
  width: 200px;
  background-color: #f1f1f1;
  position: fixed;
  height: 80vh;
  overflow: auto;
}


.off-canvas-image {
  margin-left: 30px;
  width: 80px;
  height: 75px;
  margin-top: auto;
  background-color: white;
}


.rowss {
  width: 102%;
  height: 75px;
  margin-left: -1%;
  margin-right: 50px;
  border-radius: 0px 0 20px 20px;
}

h2 {
  color: #ffffff;
}

.btn-nav:hover {
  border: 2px solid rgb(46, 46, 46);
  border-width: 0px 0px 0.5px 0px;
  width: 80%;
}

.info {
  text-align: center;
  color: white;
  margin-right: 0%;
  margin-top: -14px;
  font-size: 32px;
}
.sidebar a {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}

.sidebar a.active {
  background-color: #04AA6D;
  color: white;
}

.sidebar a:hover:not(.active) {
  background-color: #555;
  color: white;
}

div.content {
  margin-left: 200px;
  padding: 1px 16px;
  height: 1000px;
}

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {
    float: left;
  }
  div.content {
    margin-left: 0;
  }
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}

.Listflat {
  font-size: 43px;
}

.notification {
  font-size: 30px;
}

.offcanvas-start {
  width: 235px !important;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
  text-align: center;
}

img {
  width: 20px;
  border-radius: 50%;
  border: 4px solid white;
}

.abc a {
  font-weight: 2px;
}

.image {
  width: 57px;
  border-radius: 50%;
}


.offcanvas-start {
  width: 20%;
  background-color: rgba(148, 204, 250, 0.329);
  height: 96%

  ;
}

.offcanvas-body {
  flex-grow: 2;
  padding: 0rem 1rem;
background-image: url(../navbar/Menu.png);
background-repeat: no-repeat;
background-size: cover; 
}

.sidemenu-icons{
  color: white;
   font-Size: 25px; 
}
.navspan{
  font-Size: 40px;
  color:rgb(34, 34, 34); 
  margin-Left:10px;
}