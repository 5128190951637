.container-fluid-chats{
    background-color        : #ffffff;
    height                  : 80vh;
}
.comchats{
    border-Bottom           : "solid 0.0px gray"; 
}


.imgcard{
    height                  : 270px;
    width                   : 270px;
    border-Radius           : 0px
}

.react-scroll-to-bottom--css-ixmlx-1n7m0yu {
    height                  : 91%;
    overflow-y              : auto;
    width                   : 100%;
}

.row-compnaychat{
    background-color        : white;
    display                 : flex;
    justify-content         : center;
    align-items             : center;
    text-align              : center;
}