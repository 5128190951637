
.closebtn{
    float                   : right;
    margin-Top              : -28px;
    border                  : none;
    background-Color        : #ce2b2b;
    color                   : white;
    border-Radius           : 10px 
}

._1ceqH ._1lB9c {
 
    background              : #ffffff;
   
}
._1ceqH ._1Yplu ._1Pz2d {
    float                   : right;
    margin-top              : 230px;
    margin-right            : -20px;
}
._1ceqH ._3bC73 ._1dpop {
    position                : absolute;
    left                    : 53%;
    transform               : translateX(-50%);
    bottom                  : 20px;
}
._1ceqH ._3bC73 ._1dpop {
    transform               : translateX(-50%);
    bottom                  : 20px;
}
._1ceqH ._1Yplu ._1Pz2d:hover {
    margin-left             : 40px;
}

._1ceqH ._1Yplu ._2gd2_ {
   float                    : left;
}
._1ceqH ._qxztz ._1bSom {
    margin-left             : 10px;
}
._1ceqH ._1lB9c {
    background-color        : wheat;
}
._1ceqH ._1Yplu ._2gd2_ {
margin-left                 : none !important;
}

._1ceqH ._3bC73 ._1dpop {
    left                    : 50%;
}

._1ceqH ._1Yplu ._2gd2_ {
    background              : #fff;
    color                   : #185fec;
    margin-left             : -15px;
}
._1ceqH ._1Yplu ._1Pz2d:hover {
    margin-left             : -15px;
}
._1ceqH ._1Yplu ._2gd2_ {
    background              : #fff;
    color                   : #185fec;
    margin-left             : -15px;}

    ._1ceqH ._qxztz ._1bSom {
        background          : #940505;
        position            : relative;
        margin-left         : 38px;
    }

    .closebtn{
        margin-top: 10%;
        position: absolute;
    }

 