.Container-fluid-logopage{
 background-color       : #3b9bf7;
 height                 : 90vh;
 background-size        : contain;
 background-repeat      : no-repeat;
 justify-content        : center;
 align-items            : center;
 text-align             : center;
  
 
}

.wtti{
color                   : #2C2E43;
list-style              : none;
position                : relative;
}

.imgsssl{
border                  : none;
height                  : 20%;
width                   : 300px;
}

@keyframes typing {
    100%{
        left            : 100%;
        margin          : 0 -35px 0px 35px;
        
    }
}

.imgsss{
text-Align              : center;
align-Items             :center;
height                  : 45%;
width                   : 50%; 
margin-left             : 25%;
border                  : none;
}