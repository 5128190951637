.App {
  text-align: center;
}
.container-messages{
  background-color: #3b9bf7;
}
.container-app{
  background-color: #3b9bf7;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation         : App-logo-spin infinite 20s linear;
  }
}
.container-fluid-msgs{
  background-color    : #ffffff;
  height              : 100vh;

}
.container-fluid-mgs{
  background-color    : white;
height                : 100vh;
}
.css-kma3qh {
  height              : 100px;
  width               : 100%;
}

.App-header {
  background-color    : #282c34;
  min-height          : 100vh;
  display             : flex;
  flex-direction      : column;
  align-items         : center;
  justify-content     : center;
  font-size           : calc(10px + 2vmin);
  color               : white;
}

.App-link {
  color               : #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform         : rotate(0deg);
  }
  to {
    transform         : rotate(360deg);
  }
}
img {
  border-radius       : 50%;
   height             : 42px;
   width              : 45px;
  margin-top          : 0px;
  border              : 2px solid black;
}

p {
  font-family         : -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight         : 500;
  font-size           : 25px;
  margin-top          : 10px;
  margin-left         : 10px;
  margin-right        : 10px;
  overflow-wrap       : break-word;
}

.msg {
  padding             : 20px 10px 0 20px;
  margin              : 20px;
  border-radius       : 10px;
  box-shadow          : 0 0 10px rgb(164, 164, 164);
}

.sent {
  background-color    : #f8f9fa;
  color               : white;
  flex-direction      : row-reverse;
  padding             : 20px 20px 0 10px;
  text-align          : end;
  float               : right;
  display             : flex;
  flex-wrap           : wrap;
  border-radius       : 25px 0px 20px 1px;
}

.received {
  border              : 1px solid lightgray;
  background-color    : #FAFAFA;
  float               : left;
  display             : flex;
  flex-wrap           : wrap;
}
.img, svg{
  font-size           : 28px !important ;
}


.sendMsg {
  position            : fixed;
  display             : flex;
  width               : 102%;
  height              : 90px;
  bottom              : 0%;
  z-index             : 1;
  margin-left         : -6px;
  background-color    : #ffffff;
  border              : 1px solid gray;
  
}





  .msgs {
  /* margin              : 100px 0; */
  display             : flex;
  flex-direction      : column;
  margin-bottom       : 70px;
  background-color    : white;
 
}


@media (max-width: 775px) {
  p {
    font-size         : 20px;
  }
  .sent {
    padding           : 10px 10px 0 10px;
    display           : flex;
    flex-wrap         : wrap;
    
  }
  .received {
    padding           : 10px 10px 0 10px;
    display           : flex;
    flex-wrap         : wrap;
  }
  
  
}

@media (max-width: 500px) {
  p {
    font-size         : 15px;
  
  }
  .sent {
    padding           : 7px 7px 0 7px;
    
  }
  .received {
    padding           : 7px 7px 0 7px;
    display           : flex;
    flex-wrap         : wrap;
  }
  img {
    margin-top        : 0px;
    
 }
 
}

.chatscroll{
  margin-bottom: 10px;
}

.row-chats{
  align-items: center;
  display: flex;
    justify-content: center;
}