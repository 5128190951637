.container-home {
    background-image            : url(../../assets/images/islbadtraffic.png) ;
    background-repeat           : no-repeat;
    background-size             : cover;
    height                      : 80vh;
    width                       : 100%;
    padding                     : 15px;
    color                       : #2C2E43 !important; 
    border-top                  : 1px solid;
}
.img-logo{
    background-image            : url(../Home/Trafficinfo.png);
    background-size             : 100px;
    background-repeat           : no-repeat;
    background-position         : center;
    height                      : 16vh;
    width                       : 43%;
    margin-left                 : 28vw;
    display                     : flex; 
  
}


.cardimgs1{
    width                       : 120px;
    height                      : 120px;
    border-Radius               : 2px;
    display                     : flex;
    border                      : none;
    outline                     : none;
}


.btsss{
    display                     : flex;
    justify-content             : center;
}





.img-logo:hover{
    background-image            : url(../Home/Trafficinfo2.png);
    background-size             : 100px;
    background-repeat           : no-repeat;
    background-position         : center;
    height                      : 16vh;
    width                       : 43%;
    margin-left                 : 28vw;
    display                     : flex;
}


h3 {
    color                       : #2C2E43;
}
.Tlogo{
   
   background-size              : 20px;
}
h4 {
    color                       : #2C2E43;
}
.Dat {
    background-color            : rgba(231, 231, 231, 0.288);
    flex-direction              : column;
    border-radius               : 30px;
    height                      : 43vh;
    border                      : 1px solid gray;
    box-shadow                  : black 3px 4px 9px -1

}

#Card1 {
   
    background-size             : 10px;
    background-repeat           : no-repeat;
    color                       : white;
}

#Card2 {
    background-image            : url(../../assets/Home/pic2.jpg);
    background-size             : cover;
    background-repeat           : no-repeat;
    color                       : white;
}

#Card3 {
    background-image            : url(../../assets/Home/taxi1.jpeg);
    background-size             : cover;
    background-repeat           : no-repeat;
    color                       : white;   
}

#Card4 {
    background-image            : url(../../assets/Home/taxi4.jpeg);
    background-size             : cover;
    background-repeat           : no-repeat;
    color                       : white;
}

.home-btn{
    width                       : 100%; 
   height                       : 40px; 
    background-Color            : #1ab9f8;
    font-Size                   : 11px;
    border-Radius               : 5px;
    color                       : white;
    border                      : 1px solid rgb(207, 207, 207);
}


@media screen and (max-width:450px) {

    .home-btn{
        width                   : 100%; 
       height                   : 40px; 
        background-Color        : #1ab9f8;
        font-Size               :9px;
        border-Radius           : 5px;
        color                   : white;
        border                  : 1px solid rgb(207, 207, 207);
    
}
}

.home-btn:hover{
    background-color            :#d1edf8 ;
    color                       : black;
}


.Home-buttons:hover {
    background-color            : green;
}
@media screen and (max-width: 375px) {
    .custom_btn_company{
      width                     : 100% !important;
    }
  }

  .card {
    position                    : relative;
    display                     : flex;
    flex-direction              : column;
    min-width                   : 0;
    word-wrap                   : break-word;
    background-color            : #ffffff00;
    background-clip             : border-box;
    border                      : 1px solid rgba(0,0,0,.125);
    border-radius               : .25rem;
}



.row-home{
    background-image            : url(../../images/backgroundads.png);
    background-position         : center;
    background-size             : 400px;
    background-repeat           : no-repeat;
    display                     : flex;
    justify-content             : center ;
    align-items                 : center;
    text-align                  : center;
    height                      : 450px;
}

.img-icons{
    margin                      : 1%;
    height                      : 0%;
    width                       : 50px;
    border                      : none;
}
.img-icons:hover{
    margin                      : 1%;
    height                      : 0%;
    width                       : 45px;
    border                      : none;
}

.carousel-indicators {
    position                    : absolute;
    right                       : 0;
    bottom                      : 0;
    left                        : 0;
    z-index                     : 2;
    display                     : flex;
    justify-content             : center;
    padding                     : 0;
    margin-right                : 27%;
    margin-bottom               : 0rem;
    margin-left                 : 23%;
    /* list-style               : none; */
}
.row-homeicon{
    align-items                 : center;
    justify-content             : center;
    display                     : flex;
}