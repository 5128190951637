.container-fluid-carinput {
    background-color        : #3b9bf7   ;
    height                  : 100%;
}

.container-carinput {
    background-color        : rgb(240, 238, 238);
    height                  : 98%;
    margin-left             : 15px;
    margin-right            : 15px;
    border                  : 1px solid rgb(207, 206, 206);
}



.selectes {
    height                  : 50px;
    width                   : 98%;
}

.btnns-car {
    margin                  : 5px;
    height                  : 40px;
    width                   : 60px;
    background-color        : rgb(255, 255, 255);
    border                  : 1px solid rgb(201, 199, 199);

}

.btnns-car:hover {
    background-color        : rgb(39, 156, 202);
    color                   : white;
    border                  : 1px solid rgb(32, 32, 32);



}

.Conditions {
    height                  : 50px;
    width                   : 98%;
}

.row-camra {
    border                  : 0.5px solid rgb(88, 88, 88);
    border-width            : 0px 0px 1px 0px;
    padding-left            : 10px;
    padding-right           : 10px;

}

.row-condition {
    border                  : 0.5px solid rgb(88, 88, 88);
    border-width            : 0px 0px 1px 0px;

}

.input-cars[type="file"] {
    width                   : 98px;
    height                  : 80px;
    display                 : flex;
    justify-content         : center;
    align-items             : center;
    border-radius           : 130x;
    text-align              : center;
    background-image        : url('../../components/carinput/camra.png');
    background-size         : contain;
    background-position     : center;
    background-repeat       : no-repeat;
}

.loading {
    height                  : 80px;
    display                 : flex;
    justify-content         : center;
    align-items             : center;
    border-radius           : 130x;
    text-align              : center;
    background-image        : url('../../images/loader.gif');
    background-size         : contain;
    background-position     : center;
    background-repeat       : no-repeat;

}


