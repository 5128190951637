.send-image-container {
    width: 60px;
    display: flex;
    justify-content: center;
}


.MuiButton-label {
    width: 104px !important;
}

.custom-file-input {
    width: 40px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    text-align: center;
    /* background-color: #ffffff00; */
    color: rgb(255, 255, 255);
    background-image: url("../../images/gallary.png");
    background-size: 30px;
    background-repeat: no-repeat;
    margin-left: -3px;
    margin-top: 23px;
    border: none;
    
    
}

.container-sendmessagess{
    width: 100%;
}




input[type="file"]::-webkit-file-upload-button {
    visibility: hidden;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* background-image: url('../../images/ic_input_file.png'); */
}



.capture{
    margin-top: 28px;
    height: 30px;
    width: 70px;
    border: none;
}
.retake{
    margin-top: 28px;
    height: 30px;
    width: 70px;
}


.send-image-container{
    border: none;
    height:40px;
    width:12%;
    margin-Top:12px; 
    padding-Top:0px;
    padding-Bottom:10px;
    margin-Left:0px
}


.webcam-btn{
    height: 40px;
    width: 15%; 
    color: rgb(39, 156, 202); 
    margin-Top: 28px;
    margin-Left: 15px; 
    background-Color: white; 
    border-Radius: 40px; 
    border: none;
    align-Items: center; 
    text-Align: center
}

.ticamra{
    height: 40px; 
    width: 15%; 
    color: rgb(39, 156, 202); 
    margin-Top: 28px;
    margin-Left: 15px; 
    background-Color: white; 
    border: none; 
    border-Radius: 40px;
    align-Items: center; 
    text-Align: center;
}

.aicamra{
    height: 40px; 
    width: 15; 
    color: rgb(39, 156, 202); 
    margin-Top: 28px;
    margin-Left: 15px; 
    background-Color: white; 
    border: none; 
    border-Radius: 40px;
    align-Items: center; 
    text-Align: center;
}

.txtarea{
    width: 100%;
    background-Color: #f9fafb;
    padding: 0px;
    font-Size: 15px;
    font-Weight: 550;
    margin-Top: 34px;
    border: solid gray;
    border-width: 0px 0px 1px 0px;
    border-Radius: 5px;
    height: 28px;
    margin-Right: -10px;
    outline: none;
}

.voicee{
    width: 11%; 
    margin-Left: 13px; 
    height: 40px; 
    margin-Top: 28px; 
    border: none;
    background-Color: white; 
    color: rgb(39, 156, 202); 
    border-Radius: 22px
}

.iosend{
    width: 6%; 
    margin-Top: 28px;
    font-Weight: 550;
    max-Width: 200px; 
    margin-Right: 20px; 
    color: rgb(39, 156, 202); 
    border: none;
    height: 40px; 
    margin-bottom: 0px;
    background-color: rgb(255, 255, 255);
}

.btnsss{
    border: none;
    width: 60px;
    border-radius: 5px;
    background-color: rgba(112, 209, 247, 0); 
    color: rgb(255, 255, 255);
}

